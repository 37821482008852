.bdy{
    background-image: url(../../../public/media/campo.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.custom-container{
    position: relative;
    display: flex;
    padding-top: 5%;
    justify-content: top;
    align-items: center;
    flex-direction: column;
}

.sign-in-box {
    width: 400px;
    height: 400px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 50px 30px;
    border-radius: 10px;

  }

.custom-img{
    width: 50%;
    margin-top: 30rem;
}

.animado {
    font-size: 4rem;

    display: block;
    align-items: center;
    font-family: monospace;    
    white-space: nowrap;    
    border-right: 4px solid;   
    width: 20ch;
   animation: typing 2s steps(12), blink .5s infinite step-end alternate;    
    overflow:hidden;    
}
    
@keyframes typing {
    from { width: 0}
}
@keyframes blink {
    50% { border-color: transparent}
}