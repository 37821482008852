.custom-card {
    width: 90%; /* Ajusta el ancho de la tarjeta según tus preferencias */
    margin: 0 auto; /* Centra la tarjeta */
    margin-bottom: 1rem; /* Espaciado inferior */
    margin-top: 1rem;
    
  }
  
  .custom-card-body {
    display: flex;
    align-items: center;
    
  }
  
  .custom-card-image img {
    width: 100px;
    height: 100px;
    object-fit: cover; /* Ajusta el comportamiento de la imagen */
    margin: 10px;
    border-radius: 10%; /* Borde redondeado */
  }
  
  .custom-card-content {
    flex: 1; /* El texto ocupará todo el espacio disponible */
    word-wrap: break-word;
    margin-right: 5px;
  }
  
  .custom-card-content h5 {
    margin-bottom: 0.5rem; /* Espaciado inferior del título */
  }